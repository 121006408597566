
import React from 'react';
import "./index.css"
 
const Home = () => {
    return (
        <div id="home-wrapper">
            <h1>Welcome to my website! It is still very much a work in progress.</h1>
            <h3>Check out the Demo Library!</h3>
        </div>
    );
};
 
export default Home;
